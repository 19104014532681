<template>
  <div>
    <UserRegistration></UserRegistration>
  </div>
</template>

<script>
import UserRegistration from "@/components/registration/UserRegistration.vue";

export default {
  name: "RegistrationView",
  components: {
    UserRegistration
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>

</style>


