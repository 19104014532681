export default class FtRegistrationReq {
  constructor(
    id,
    username,
    password,
    email,
    whatsApp,
    tokenMedia,

    countryCode,
    fdivisionBean,
    type,
    roleName,
    remarkJson,
    firstName,
    lastName,
    fullName,
    timeStart,
    durations,
    timeEnd ,

  ) {
    this.id = id;
    this.username = username;
    this.password = password;
    this.email = email;
    this.whatsApp = whatsApp;
    this.tokenMedia = tokenMedia;

    this.countryCode = countryCode;
    this.fdivisionBean = fdivisionBean;
    this.type = type;
    this.roleName = roleName;
    this.remarkJson = remarkJson;
    this.firstName = firstName;
    this.lastName = lastName;
    this.fullName = fullName;
    this.timeStart = timeStart;
    this.durations = durations;
    this.timeEnd = timeEnd;

  }
}
