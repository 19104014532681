import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtRegistrationReqService {
    getApiUrl() {
        return API_URL
    }
    getAllFtRegistrationReq(){
        return axios.get(API_URL + `getAllFtRegistrationReq`, { headers: authHeader() });
    }
    getAllFtRegistrationReqContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFtRegistrationReq`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFtRegistrationReqContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFtRegistrationReqById(id){
        return axios.get(API_URL + `getFtRegistrationReqById/${id}`, { headers: authHeader() });
    }

    updateFtRegistrationReq(item){
        return axios.put(API_URL + `updateFtRegistrationReq/${item.id}`, item, {headers: authHeader()})
    }
    createFtRegistrationReq(item){
        return axios.post(API_URL + `createFtRegistrationReq`, item, {headers: authHeader()})
    }
    createFtRegistrationReqPublic(item){
        return axios.post(API_URL + `public/createFtRegistrationReq`, item, {headers: authHeader()})
    }
    regValidateOtp(id, otp){
        return axios.get(API_URL + `public/regValidateOtp/${id}/${otp}`, {headers: authHeader()})
    }


    checkAndCreateResetPasswordReqPublic(item){
        return axios.post(API_URL + `public/checkAndCreateResetPasswordReqPublic`, item, {headers: authHeader()})
    }

    resetValidateOtpPasswordPublic(id, otp, password){
        return axios.post(API_URL + `public/resetValidateOtpPassword/${id}/${otp}`, password, {
            headers: {
                ...authHeader(),
                'Content-Type': 'text/plain'
            }}
        )
    }



    deleteFtRegistrationReq(id){
        return axios.delete(API_URL + `deleteFtRegistrationReq/${id}`, {
            headers: authHeader()
        });
    }

    deleteFtRegistrationReqAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFtRegistrationReqAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }

    deleteAllFtRegistrationReq(itemIds){

        return axios.delete(API_URL + `deleteAllFtRegistrationReq`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtRegistrationReqService()